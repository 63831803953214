export default {
    enterMatchData: "Enter Match Data",
    selectTeam: "Select a team",
    selectTeamLocked: "Team selection locked",
    selectTeamFirst: "Select a team first",
    selectRound: "Select a round",
    selectTable: "Select a table",
    refereeTools: "Referee Tools",
    resetForm: "Reset scoring form",
    accessResources: "Access Season Resources",
    enableImages: "Enable Images",
    scoringInput: "Scoring Input",
    enableEditing: "Click the edit icon to unlock",
    errorLoadingRounds: "Something went wrong while loding the rounds.",
    alreadyEntered: "Already entered",
    challengeGuide: "Challenge Guide",
    challengeUpdates: "Challenge Updates",
    confirmResult: "Confirm Result",
    submit: "Submit",
    GPScore: "Gracious Professionalism Score",
    noShow: "No-Show",
    signature: "Signature",
    scrollToTop: "Scroll To Top",
    comments: "Comments",
    fixIt: "Fix It",
};
