export default {
    title: 'Exports',
    rubrics: 'Jury Formulieren',
    rubricsExplain: 'Deze export bevat alle jury formulieren, met speciale markeringen voor de digitale verwerking. Er word gebruik gemaakt van de Teams zoals geregistreerd in het systeem.',
    ojs: 'Official Jury Spreadsheet Export',
    ojsExplain: 'Maakt de verschillende export bestanden voor OJS vanuit de FLLTools site.',
    warningTime: 'Het kan een paar minuten duren om deze export te genereren.',
    judgeList: "Jury schema per lane",
    judgeListExplain: "Dit bestand bevat een overzicht met voor alle jurylanes met welke teams op welk moment verschijnen",
    matchList: "Wedstrijdschema's per tafel",
    matchListExplain: "Dit bestand bevat een overzicht met alle wedstrijden gesorteerd per tafel",
    generate: "Genereer exports",
    generateExplain: "Door de wedstrijd hieronder te selecteren worden alle exports gegenereerd. Dit kan enkele minuten duren."
};
