export default {
    title: "Exports",
    rubrics: "Judge Rubrics",
    rubricsExplain: "This export contains all the rubrics sheets, with special marks for digital processing. This uses the Teams as they are in the FLLTools system.",
    ojs: "Offical Judging Sheet Export",
    ojsExplain: "Creates various export files for OJS from the FLLTools website.",
    warningTime: "It can take a few minutes to generate this export",
    judgeList: "Jury schedule per lane",
    judgeListExplain: "This file contains all jurylanes with the time when the teams should appear",
    matchList: "Match schedule per table",
    matchListExplain: "This file contains all tables with the matches and when the teams should appear",
    generate: "Generate exports",
    generateExplain: "By selecting a tournament in below, you can generate a lot of documents with this tool.",
    usefulExports: "Useful exports",
    usefulExportsExplain: "FLLTools can generate a lot of useful documents for your final. Below you can download them. On our wiki page you can find what each is. If you need specialized documents, contact us so we can look what we can do for you.",
    tableIndicator: "Table Indicator",
    tableIndicatorExplain: "A4 papers with the teaminfo, logo's to put on the table to indicate where the teams pit area is",
    teams4teams: "Teams4teams forms",
    teams4teamsExplain: "Teams4teams nominations forms.",
    presentatorPages: "Pitchcards with match info",
    presentatorPagesExplain: "Pitchcards with match info that the presentors can use while presenting",
    headRefereeExport: "CSV file with time/table information",
    headRefereeExportExplain: "Easy view for the head referee or match secetariat to have a nice overview of the matches of the day",


};
