export default {
    enterMatchData: "Voer wedstrijd informatie in",
    selectTeam: "Selecteer een team",
    selectTeamLocked: "Team selectie geblokeerd",
    selectTeamFirst: "Selecteer eerst een team",
    selectRound: "Selecteer een ronde",
    selectTable: "Selecteer een tafel",
    refereeTools: "Scheidsrechters tools",
    resetForm: "Reset scoreformulier",
    accessResources: "Bekijk seizoensinformatie",
    enableImages: "Zet plaatjes aan",
    scoringInput: "Score Input",
    enableEditing: "Klik op het bewerk icoontje om het te bewerken",
    errorLoadingRounds: "Er is iets mis gegaan tijdens het laden van de rondes",
    alreadyEntered: "Er is al een score ingevuld",
    challengeGuide: "Challenge Guide",
    challengeUpdates: "Challenge Updates",
    confirmResult: "Bevestig resultaat",
    submit: "Verstuur",
    GPScore: "Gracious Professionalism Score",
    noShow: "No-Show",
    signature: "Handtekening",
    scrollToTop: "Scroll naar boven",
    comments: "Commentaar",
    fixIt: "Los op",
};
